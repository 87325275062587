@import '../App.scss';

.intro-section {
  &__container {
    background-color: var(--color-yellow);
    color: var(--color-dark-green);
  }

  &__greeting {
    font-size: calc(var(--size-small) + 2vmin);
    max-width: fit-content;
    font-weight: 700;
    line-height: 1.1;

    &--large-bold {
      align-self: center;
      font-size: calc(var(--size-2xlarge) + 2vmin);
      font-weight: 900;
      letter-spacing: -0.5px;

      @media screen and (max-width: 820px) {
        font-size: calc(var(--size-xlarge) + 2vmin);
        align-self: auto;
      }

    }

    &--small-italic {
      font-size: calc(6px + 1vmin);
      font-style: italic;
      font-weight: 400;
    }
  }

  &__intro-text {
    line-height: 28px;
    margin-top: var(--s-large);
    margin-bottom: var(--s-large);
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: var(--color-dark-green);
    cursor: pointer;

    &-icon {
      margin-left: var(--s-2xsmall);
    }
  }

  &__link:visited {
    color: var(--color-dark-green);
  }

  &__link:hover {
    opacity: 60%;
  }
}

h2 {
  margin-bottom: 0px;
}