@import '../App.scss';

.aboutme-section {
  &__container {
    background-color: var(--color-light-green);
    color: var(--color-yellow);
  }

  &__heading {
    font-size: calc(24px + 3vmin);
  }
}