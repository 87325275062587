@import '../App.scss';

.menu {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-pistachio);
    font-family: "Roboto Mono";
    font-size: var(--size-medium);
    color: var(--color-black);
    flex-wrap: wrap;

    &__list {
        display: flex;
        justify-content: flex-end;
        list-style-type: none;
        margin: 0px;
        padding: var(--s-large) var(--s-2xlarge);

        @media screen and (max-width: 280px) {
          padding: var(--s-large) 19px;
        }

        li a {
            text-decoration: none;
        }

        li a:hover {
            color: black;
            font-weight: 700;
        }
    }

    &__item {
        font-family: "Roboto Mono";
        font-size: var(--size-medium);
        color: var(--color-black);
        opacity: 60%;
        height: 24px;
        letter-spacing: 0.4px;
        padding: var(--s-small);
        cursor: pointer;
        text-decoration: none;

        &-selected {
            border-bottom: solid;
            color: var(--color-black);
            font-weight: 900;
        }
    }
}
