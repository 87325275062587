@import '../App.scss';

.contact-content {
  align-self: flex-start;
  width: 100%;

    &__heading {
        font-family: "Caveat";
        font-size: 24px;
        flex-direction: row;
        display: flex;
        align-items: center;
    }

    &__link-list {
      display: flex;
    }

    &__link {
        color: black;
        text-decoration: none;
        margin: 0px var(--s-xsmall) 0px 0px;
    }
    &__link {
        a:visited, a:link {
            color: black;
            text-decoration: underline;
        }

        a:hover {
          opacity: 60%;
        }
    }

    h3 {
      margin: var(--s-medium) 0px var(--s-medium);
    }
  }

  .email {
    margin-top: 24px;
  }

  .icon {
    margin-left: var(--s-small);
  }