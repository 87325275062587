:root {
  --color-black: #000000;
  --color-yellow: #DBF227;
  --color-dark-green: #042940;
  --color-light-green: #005C53;
  --color-yellow-green: #9FC131;
  --color-beije: #D6D58E;
  --color-pistachio: #E1F0DA;
  --ipad-air-width: 1180px;
  --s-2xsmall: 4px;
  --s-xsmall: 8px;
  --s-small: 12px;
  --s-medium: 14px;
  --s-large: 24px;
  --s-xlarge: 54px;
  --s-2xlarge: 72px;
  --size-small: 14px;
  --size-medium: 16px;
  --size-large: 48px;
  --size-xlarge: 56px;
  --size-2xlarge: 96px;
}

.app {
  background-color: var(--color-pistachio);
  overflow-x: hidden;
}

.app-header {
  display: contents;
}

.main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.theme-button {
  align-self: flex-end;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 0px;
  cursor: pointer;
}

.contact-title {
  display: flex;
  align-items: center;
}

ul.no-bullet {
  list-style-type: none;
  padding-left: 0px;
  margin-top: 0px;
}

button {
  height: auto;
  width: auto;
}

html {
  scroll-behavior: smooth;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  scroll-margin-top: var(--s-xlarge);
  font-family: "Inter";
  font-size: large;
  padding: 0px calc(372px + 2vmin);
  min-height: calc(100vh);

  @media screen and (max-width: 412px) {
    padding: calc(72px + 2vmin) calc(372px + 2vmin);
  }
}

footer {
  padding: var(--s-large) var(--s-2xlarge);

  @media screen and (max-width: 280px) {
    padding: var(--s-2xsmall) var(--s-small);
  }
}
