@import '../App.scss';

.hobby-section {
    &__container {
        background-color: var(--color-yellow-green);
        color: var(--color-dark-green);
    }

    &__heading {
        font-size: calc(24px + 3vmin);
    }

    &__content {
        a:visited, a:link {
            color: var(--color-dark-green);
            font-weight: 700;
        }
    }
}

